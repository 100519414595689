.responsivehero {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    margin-top: 8%;
}

.heading-size {
    font-size: xxx-large;
}

.para-font {
    font-size: 22px;
}

.schbutton {
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 108.5vh;
    text-align: center;
}

.schfont {
    font-size: medium;
}

.scharrow {
    margin-left: 1.2px;
}

/* Large Devices (Desktops and Larger Screens) */
@media (min-width: 2560px) and (min-height: 1164px) {
    .responsivehero {
        margin-top: 15rem;
    }

    .heading-size {
        font-size: 4rem;
    }

    .para-font {
        font-size: 2.5rem;
    }

    .schbutton {
        height: 112vh;
    }

    .schfont {
        font-size: 1.8rem;
    }

    .scharrow {
        width: 2rem;
    }
}

/* 4K Screens (Ultra HD, 3840x2160 and above) */
@media (min-width: 3840px) and (min-height: 2160px) {
    .responsivehero {
        margin-top: 15rem;
    }

    .heading-size {
        font-size: 5rem;
    }

    .para-font {
        font-size: 3rem;
    }

    .schbutton {
        height: 120vh;
    }

    .schfont {
        font-size: 3rem;
    }

    .scharrow {
        width: 4rem;
    }
}

/* 1728 x 1117 Resolution */
@media (min-width: 1728px) and (max-width: 1919px) and (min-height: 1117px) and (max-height: 1199px) {
    .responsivehero {
        margin-top: 15rem;
    }

    .heading-size {
        font-size: 3rem;
    }

    .para-font {
        font-size: 1.8rem;
    }

    .schbutton {
        height: 112vh;
    }

    .schfont {
        font-size: 1.6rem;
    }

    .scharrow {
        width: 2rem;
    }
}

/* 1504 x 1003 Resolution */
@media (min-width: 1504px) and (max-width: 1727px) and (min-height: 1003px) and (max-height: 1116px) {
    .responsivehero {
        margin-top: 13rem;
    }

    .heading-size {
        font-size: 2.8rem;
    }

    .para-font {
        font-size: 1.6rem;
    }

    .schbutton {
        height: 111vh;
    }

    .schfont {
        font-size: 1.4rem;
    }

    .scharrow {
        width: 1.8rem;
    }
}

/* Laptops & Small Desktops (1366x768, 1280x800) */
@media (min-width: 1280px) and (max-width: 1439px) {
    .responsivehero {
        margin-top: 7rem;
    }

    .heading-size {
        font-size: 2.5rem;
    }

    .para-font {
        font-size: 1.4rem;
    }

    .schbutton {
        height: 106vh;
    }

    .schfont {
        font-size: 1.3rem;
    }

    .scharrow {
        width: 1.8rem;
    }
}

/* Tablets (iPads, Surface Pro, etc.) */
@media (min-width: 768px) and (max-width: 1023px) {
    .responsivehero {
        margin-top: 5rem;
    }

    .heading-size {
        font-size: 2rem;
    }

    .para-font {
        font-size: 1rem;
    }

    .schbutton {
        height: 90vh;
    }

    .schfont {
        font-size: 1rem;
    }

    .scharrow {
        width: 1.5rem;
    }
}

@media (min-width: 815px) and (max-width: 1024px) {
    .responsivehero {
        margin-top: 11rem;
    }

    .heading-size {
        font-size: 2rem;
    }

    .para-font {
        font-size: 1rem;
    }

    .schbutton {
        height: 110vh;
    }

    .schfont {
        font-size: 1rem;
    }

    .scharrow {
        width: 1.5rem;
    }
}

/* Large Mobile Screens (iPhone Pro Max, Samsung Ultra) */
@media (min-width: 414px) and (max-width: 767px) {
    .responsivehero {
        margin-top: 3rem;
    }

    .heading-size {
        font-size: 1.8rem;
    }

    .para-font {
        font-size: 1rem;
    }

    .schbutton {
        height: auto;
    }

    .schfont {
        font-size: 1rem;
    }

    .scharrow {
        width: 1.2rem;
    }
}

/* Small Mobile Screens (iPhone SE, Small Android Phones) */
@media (max-width: 413px) {
    .responsivehero {
        margin-top: 2rem;
    }

    .heading-size {
        font-size: 1.5rem;
    }

    .para-font {
        font-size: 0.9rem;
    }

    .schbutton {
        height: auto;
    }

    .schfont {
        font-size: 0.9rem;
    }

    .scharrow {
        width: 1rem;
    }
}

@media (min-width: 768px) and (max-width: 815px) {
    .responsivehero {
        margin-top: 15rem;
    }

    .heading-size {
        font-size: 2rem;
    }

    .para-font {
        font-size: 1rem;
    }

    .schbutton {
        height: 139vh;
    }

    .schfont {
        font-size: 1rem;
    }

    .scharrow {
        width: 1.5rem;
    }
}

@media (min-width: 425px) and (max-width: 815px) {
    .responsivehero {
        margin-top: 15rem;
    }

    .heading-size {
        font-size: 2rem;
    }

    .para-font {
        font-size: 1rem;
    }

    .schbutton {
        height: 139vh;
    }

    .schfont {
        font-size: 1rem;
    }

    .scharrow {
        width: 1.5rem;
    }
}

@media (min-width: 375px) and (max-width: 815px) {
    .responsivehero {
        margin-top: 15rem;
    }

    .heading-size {
        font-size: 2rem;
    }

    .para-font {
        font-size: 1rem;
    }

    .schbutton {
        height: 139vh;
    }

    .schfont {
        font-size: 1rem;
    }

    .scharrow {
        width: 1.5rem;
    }
}
@media (min-width: 320px) and (max-width: 815px) {
    .responsivehero {
        margin-top: 15rem;
    }

    .heading-size {
        font-size: 2rem;
    }

    .para-font {
        font-size: 1rem;
    }

    .schbutton {
        height: 139vh;
    }

    .schfont {
        font-size: 1rem;
    }

    .scharrow {
        width: 1.5rem;
    }
}