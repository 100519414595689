.cstmbgmain{
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body #root {
  margin: 0;
  height: 100vh;
}

.cstmbgmain {
  /* background-color: #02AAE2; */
  background-color: #81d5f1;
  padding: 32px;
  position: relative;
  height: 100%;
  overflow-y: hidden;
}
.btninst{
  width: 100%;
  height: 24px;
}
#gameControls{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:12px
}
#startButton,
#playButton,
#pauseButton,
#restartButton
#exitButton {
  /* background-color: #82e9ae; */
  padding: 6px 16px;
  border-radius: 10px;
  color: #212121;
  font-family: "Rubik", serif;
  font-weight: 500;
  border: 1px solid #0b8e44;
}
#startButton{
  background-color: #8EF299;
}
#playButton{
  background-color: #F6FFB8;
}
#pauseButton{
  background-color: #F2EDFE;
}
#restartButton {
  background-color: #FFF1E4;
  padding: 6px;
  border-radius: 10px;
  border: 1px solid #0b8e44;
}


#exitButton {
  background-color: #8EF299; /* Same as Start Button */
  padding: 6px 16px;
  border-radius: 10px;
  color: #212121;
  font-family: "Rubik", serif;
  font-weight: 500;
  border: 1px solid #0b8e44;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#exitButton:hover {
  background-color: #7ADC8D; /* Slightly Darker Green on Hover */
}

.psdgm {
  font-family: "Rubik", serif;
  font-weight: 600;
  font-size: 20px;
  background-color: #efd0fe9e;
  padding: 6px 24px;
  width: fit-content;
  margin: 0px auto;
  border-radius: 6px;
}
.aircraftlg {
  max-width: 100%;
  height: 62px !important;
  display: block;
}
#shootingButton {
  /* background-color: #E1F5FC; */
  /* border: .3px solid #212121; */
  /* padding: 4px 6px; */
  height: 142px;
  width: 188px;
  margin-bottom: -10px;
  background-repeat: no-repeat;
  z-index: 3;
  /* border-radius: 6px; */
  display: block;
  background-image: url("../assets/logo.svg");
  background-position: center;
  object-fit: cover;
}
.cldspamn {
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 20%;
  left: 3%;
}
.cldspamn2 {
  max-width: 100%;
  height: 72px;
  position: absolute;
  top: 30%;
  right: 2%;
}
.cldspamn4 {
  max-width: 100%;
  height: 62px;
  position: absolute;
  bottom: 7%;
  left: 6%;
}
.cldspamn3 {
  max-width: 100%;
    height: 92px;
    position: absolute;
    bottom: 16%;
    right: 1%;
}
.animationmn {
  animation: linear infinite alternate;
  animation-name: run;
  animation-duration: 8s;
}

@-webkit-keyframes run {
  0% {
    left: 32px;
    transform: translateX(32px);
  }
  100% {
    left: 8%;
    transform: translateX(8%);
  }
}
.animationmnn {
  animation: linear infinite alternate;
  animation-name: runn;
  animation-duration: 8s;
}

@-webkit-keyframes runn {
  0% {
    right: 32px;
    transform: translateX(32px);
  }
  100% {
    right: 5%;
    transform: translateX(5%);
  }
}
.spcbtnmn{
  margin-right: 10px;
}
.scorealign{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  border-radius: 28px;
  border: 1px solid rgba(145, 105, 246, 0.35);
  background: rgba(212, 216, 243, 0.59);
  padding: 8px;
}
.coinscre{
  max-width: 100%;
  height: 28px;
  margin-right: 4px;
}
#currentScore{
  border-radius: 20px;
  border: 0.5px solid #000;
  background: #C6FF00;
  padding: 5px 16px;
  margin-left: 4px;
  font-size: 20px;
}
.gameboxcontainer {
  margin: 22px auto 0px auto;
  width: 75%;
  height: 66vh;
  border-radius: 6px;
}
.clrwhiteup {
  align-items: center;
}
.lifesmn{
  font-size: 28px;
}
.clrwhite {
  background-color: #81d5f100;
}
.bullet {
  position: absolute;
  width: 16px;
  height: 40px;
  /* background: black; */
  background-repeat: no-repeat;
  z-index: 1;
  border-radius: 10;
  display: block;
  bottom: 30px;
  background-image: url("../assets/thunder.svg");
  object-fit: contain;
  /* Ensure the bullets are above the aircraft */
  /* animation: slide 7s 1 linear forwards; */
}

.pauseGame {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.hide {
  display: none;
}
.show_pause {
  display: flex;
  z-index: 100;
}
@keyframes slide {
  0% {
    bottom: 5%;
    opacity: 1;
  }
  99% {
    bottom: 99%;
    opacity: 1;
  }
  100% {
    bottom: 100%;
    opacity: 0;
    display: none;
  }
}

@-webkit-keyframes slide {
  0% {
    bottom: 0%;
    opacity: 1;
  }
  99% {
    bottom: 99%;
    opacity: 1;
  }
  100% {
    bottom: 100%;
    opacity: 0;
    display: none;
  }
}
.gameArea{
  padding: 0px 42px;
}
.obstacle {
  position: absolute;
  padding-top: 10px;
  border-radius: 10px;
  /* border: .3px solid #ff5b5b;
  background: rgb(255, 245, 245); */
  color: rgb(106, 0, 0);
  /* rotate: 90deg; */
  text-align: center;
  font-size: 14px;
  font-family: "Rubik", serif;
  font-weight: 600;
  text-transform: capitalize;
  z-index: 4; /* Ensure the obstacles and bullets are above the aircraft */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease; /* Smooth dissolve transition */
  background-image: url("../assets/Vector.svg");
  background-size: contain;
  height: 109px;
  width: 204px;
  background-repeat: no-repeat;
  background-position: center;
}
.d-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.clrslm {
  color: #0b0b0b;
  background-color: #D4D8F3;
  padding: 3px 6px;
  border: 0.3px solid #02aae2;
  border-radius: 6px;
  height: fit-content;
}
.btnbtminst{
  max-width: 100%;
  height: 42px;
  margin: 0px auto 4px auto;
}
.leftbtnbtm, .spcnmg{
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
.spcnmg{
  margin-right: 30px;
}
.spcnmglr{
  margin-right: 20px;
}
.lftrgt{
  display: flex;
}
.piece {
  position: absolute;
  width: 120px;
  height: 90px;
  background: #ffe7e6;
  border-radius: 50%;
  opacity: 0.9;
  animation: explode 0.1s ease-out forwards;
}

@keyframes explode {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.3) translateY(20px);
    opacity: 0;
  }
}

.coin {
  position: absolute;
  width: 66px;
  height: 66px;
  background: gold;
  border-radius: 50%;
  box-shadow: 0 0 5px yellow;
  background-image: url("../assets/coin.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: fade-in .2s ease-in-out,coin-explode 0.2s 0.3s ease-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Faster coin blasting effect */
@keyframes coin-explode {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.flash {
  animation: flash-red 0.3s;
}
@keyframes flash-red {
  0% {
    background: red;
  }
  100% {
    background: skyblue;
  }
}

.shake {
  animation: shake 0.3s;
}
@keyframes shake {
  0% {
    transform: translate(2px, 2px);
  }
  25% {
    transform: translate(-2px, -2px);
  }
  50% {
    transform: translate(2px, -2px);
  }
  75% {
    transform: translate(-2px, 2px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* 
flex flex-row justify-between 
relative h-full
*/

.drop-shadow-2xl {
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.25); /* drop-shadow-2xl */
}

.border {
  border-width: 1px; /* border */
}

.rounded-md {
  border-radius: 0.375rem; /* rounded-md */
}

.border-blue-900 {
  border-color: #1e3a8a; /* border-blue-900 */
}

.border-solid {
  border-style: solid; /* border-solid */
}

.mt-2 {
  margin-top: 0.5rem; /* mt-2 */
}

.relative {
  position: relative; /* relative */
}

.h-full {
  height: 100%; /* h-full */
}

.overflow-hidden {
  overflow: hidden; /* overflow-hidden */
}

.border {
  border-width: 1px; /* border */
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0px;
}
}
