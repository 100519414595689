@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import 'react-toastify/dist/ReactToastify.css';

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Rubik', 'Plus Jakarta Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}



.rubik {
  font-family: "Rubik", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.font-jakarta {
  font-family: "Plus Jakarta Sans", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grayscale {
  opacity: 0.5;

}

.hover\:grayscale-0:hover {
  opacity: 1;
}

/* Row 1: Left to Right Animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  display: flex;
  animation: scroll 40s linear infinite;
}

.animate-scroll:hover {
  animation-play-state: paused;
  /* Pause only this row */
}


@keyframes scroll-left-to-right-footer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}


.animate-scroll-left-to-right-footer {
  display: flex;
  animation: scroll-left-to-right 40s linear infinite;
}

.animate-scroll-left-to-right-footer:hover {
  animation-play-state: paused;
  /* Pause only this row */
}

/* Row 2: Right to Left Animation */
@keyframes scroll-left-to-right {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}


.animate-scroll-left-to-right {
  display: flex;
  width: 200%;
  scroll-behavior: smooth; 
  animation: scroll-left-to-right 60s linear infinite; /* Reduced duration */
}

.animate-scroll-left-to-right:hover {
  animation-play-state: paused;
  /* Pause only this row */
}

/* Flip Card Styles */
.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 260px;
  height: 100px;
  /* Adjust based on your card height */
  transition: transform 0.6s;
  transform-style:preserve-3d ;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 110%;
  height: 90%;
  backface-visibility: hidden;
  border-radius: 8px;
  /* Match your card's border radius */
}

.flip-card-front {
  background-color: white;
}

.flip-card-back {
  background-color: #f5f5f5;
  transform: rotateY(180deg);
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.animate-infinite-scroll {
  width: 200%;
  animation: scroll 50s linear infinite;
}

.animate-infinite-scroll:hover {
  animation-play-state: paused;
}

.scroll-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.scrolling-content {
  display: flex;
  width: fit-content;
  animation: scroll-animation 60s linear infinite;
}

.scroll-container:hover .scrolling-content {
  animation-play-state: paused;
}

@keyframes scroll-animation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.blog-card:hover~.blogs-container,
.blogs-container:hover {
  animation-play-state: paused;
}

/* Parent button styling */
button {
  position: relative;
  overflow: hidden;
}

/* Loading bar animation */
.loading-bar {
  width: 0;
  /* Initial width */
  position: absolute;
  left: 0;
  top: 0;
  background-color: #222222;
  height: 100%;
  z-index: 1;
  animation: loading 0.5s linear forwards;
  /* Adjusted duration */
}

@keyframes loading {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}



@keyframes flashlight {
  0% {
    background-position: -100% 0%;
  }
  50% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}

.rainbow-flash-effect {
  position: relative;
  display: inline-block;
  color: white;
  font-weight: bold;

  /* Flash animation */
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0.5) 20%,
    rgba(255, 255, 0, 0.7) 40%,
    rgba(0, 255, 0, 0.7) 60%,
    rgba(0, 0, 255, 0.5) 80%,
    rgba(255, 0, 255, 0) 100%
  );
  background-size: 300% 100%;
  background-clip: text;
  -webkit-background-clip: text;

  /* Text remains white */
  text-fill-color: white;
  -webkit-text-fill-color: white;

  /* Glowing trail */
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.6), 0 0 12px rgba(255, 255, 255, 0.4);

  animation: flashlight 3s infinite linear;
}


@keyframes blinkEffect {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }
}

.rainbow-effect {
  background: linear-gradient(90deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd0, #006eff, #b400ff, #ff008c);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbowText 6s ease infinite, blinkEffect 1.5s ease-in-out infinite;
}

.rainbow-effect img {
  filter: hue-rotate(360deg);
  animation: rainbowText 6s ease infinite, blinkEffect 1.5s ease-in-out infinite;
}


#loading-screen {
  transition: opacity 0.5s ease-in-out;
}

@keyframes slideReverse {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.animate-loop-reverse {
  animation: slideReverse 10s linear infinite;
}


@media screen and (width: 1440px) and (height: 1117px) {
  .heading-1440 {
    font-size: 80px !important;
    margin-top: 30rem;
  }
  
  .text-1440 {
    font-size: 30px !important;
    max-width: 65%;
    padding-bottom: 150px;
    line-height: 100px;
  }
  
  .margin-1440 {
    margin-bottom: 28rem;
  }

  .Schedule-1440 {
    font-size: 35px;

  }
 }


 @media screen and (width: 1728px) and (height: 1117px) {
  .heading-1728 {
    font-size: 80px !important;
    margin-top: 30rem;
  }
  
  .text-1728 {
    font-size: 30px !important;
    max-width: 65%;
    padding-bottom: 15px;
  }
  
  .margin-1728 {
    margin-bottom: 28rem;
  }

  .Schedule-1728 {
    font-size: 35px;

  }

 }


 @media screen and (width: 1504px) and (height: 1003px) {
  .heading-1504 {
    /* font-size: 80px !important; */
    margin-top: 30rem;
  }
  
  .text-1504 {
    font-size: 30px !important;
    max-width: 65%;
    padding-bottom: 50px;
    line-height: 40px;
  }
  
  .margin-1504 {
    margin-bottom: 28rem;
  }

  .Schedule-1504 {
    font-size: 30px;

  }

 }


 /* @media screen and (min-width: 453px) and (max-width: 575px) {
  .Schedule-453 {
    padding-top: 90px;
  }
} */

/* @media screen and (min-width: 430px) and (max-width: 932px) {
  .Schedule-430 {
    padding-top: 66px;
  }
} */